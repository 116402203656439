<template>
  <v-menu v-if="order.orderType" v-model="orderTypeMenu" :disabled="!clickable" offset-y>
    <template v-slot:activator="{ props }">
      <v-chip
        v-bind="{ ...$attrs, ...props }"
        :append-icon="clickableAndChangeable ? 'mdi-chevron-down' : undefined"
        class="flex-shrink-0 font-weight-bold px-1"
        :class="textColor"
        :color="color"
        label
        :text-color="textColor"
        variant="flat"
      >
        {{ order.orderType.name }}
      </v-chip>
    </template>
    <v-list v-if="clickableAndChangeable" class="qtm-border">
      <v-list-item v-for="(orderType, i) in orderTypes" :key="i" @click="setOrderType(orderType)">
        <v-list-item-title class="text-capitalize pull-right">
          <template v-slot:prepend>
            <v-icon class="mr-1">
              mdi-checkbox-{{ (order.orderType.value === orderType.value) ? 'marked' : 'blank' }}-outline
            </v-icon>
          </template>
          {{ orderType.name }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
const cybColor = { color: 'light-teal', textColor: 'text-interactive' }
const diyColor = { color: 'light-grey', textColor: 'text-secondary' }
const rfqColor = { color: 'background', textColor: 'text-primary' }
const colorMap = {
  1: rfqColor,
  2: diyColor,
  3: cybColor,
  4: { color: 'mid-dark-grey', textColor: 'white' },
  5: rfqColor,
  6: diyColor,
  7: cybColor,
  8: { color: 'indigo lighten-1', textColor: 'blue-grey lighten-5' },
  9: { color: 'green lighten-4', textColor: 'green darken-3' },
}

export default {
  name: 'order-type-chip',
  props: {
    order: {
      type: Object,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      orderTypeMenu: false,
    }
  },
  computed: {
    color() {
      return colorMap[this.order.orderType.value]?.color || 'background'
    },
    clickableAndChangeable() {
      return this.clickable && this.order.allowedOrderTypeChanges.length !== 0 && this.order.canChangeOrderType
    },
    orderTypes() {
      return this.order.allowedOrderTypeChanges.map((orderType) => ({
        name: orderType.name, value: orderType.value
      }))
    },
    textColor() {
      return colorMap[this.order.orderType.value]?.textColor || 'primary'
    },
  },
  methods: {
    getColor(orderTypeValue) {
      return colorMap[orderTypeValue]
    },
    async setOrderType(orderType) {
      if (orderType.value === this.order.orderType.value) {
        return
      }
      const updateData = {
        id: this.order.id,
        order_type: orderType.value,
      }
      try {
        const updatedOrder = await this.$api.v1.rfqs.changeOrderType(updateData)
        this.$store.commit('admin/updateOrder', updatedOrder)
      }
      catch (error) {
        this.$error.report(error)
      }
    }
  }
}
</script>
